var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contacts--list__footer"},[_c('ul',{staticClass:"pagination"},[(_vm.prev === 0)?_c('li',{staticClass:"pagination__item prev button__disabled"},[_c('span',{staticClass:"icon"},[_c('svg',{attrs:{"width":"20","height":"20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h20v20H0z"}}),_c('path',{attrs:{"d":"M16.667 9.167H6.526l4.658-4.659-1.183-1.175L3.334 10l6.667 6.667 \n\t\t\t\t\t1.175-1.175-4.65-4.659h10.141V9.167z","fill":"#C4C4C4"}})])]),_vm._v("\n\t\t\tPrev\n\t\t")]):_c('li',{staticClass:"pagination__item"},[_c('router-link',{attrs:{"to":{
					name: _vm.baseUrl,
					query: {
						limit: _vm.limit,
						offset: _vm.offset(_vm.prev - 1)
					}
				}}},[_c('span',{staticClass:"icon"},[_c('svg',{attrs:{"width":"20","height":"20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h20v20H0z"}}),_c('path',{attrs:{"d":"M16.667 9.167H6.526l4.658-4.659-1.183-1.175L3.334 10l6.667 6.667 \n\t\t\t\t\t\t1.175-1.175-4.65-4.659h10.141V9.167z","fill":"#C4C4C4"}})])]),_vm._v("\n\t\t\t\tPrev\n\t\t\t")])],1),_vm._l((_vm.pagesArray),function(page){return _c('li',{key:page,class:{ active: _vm.current === page }},[(_vm.current !== page)?_c('router-link',{staticClass:"pagination__item",attrs:{"to":{
					name: _vm.baseUrl,
					query: {
						limit: _vm.limit,
						offset: _vm.offset(page - 1)
					}
				}}},[_vm._v("\n\t\t\t\t"+_vm._s(page)+"\n\t\t\t")]):_c('span',{staticClass:"pagination__item current"},[_vm._v(_vm._s(page))])],1)}),(_vm.current === _vm.endIndex)?_c('li',{staticClass:"pagination__item next button__disabled"},[_vm._v("\n\t\t\tNext\n\t\t\t"),_c('span',{staticClass:"icon"},[_c('svg',{attrs:{"width":"20","height":"20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"none","d":"M20 0H0v20h20z"}}),_c('path',{attrs:{"d":"M3.333 9.167h10.141L8.816 4.508l1.183-1.175L16.666 10l-6.667 \n\t\t\t\t\t6.667-1.175-1.175 4.65-4.659H3.333V9.167z","fill":"#fff"}})])])]):_c('li',{staticClass:"pagination__item next"},[_c('router-link',{attrs:{"to":{
					name: _vm.baseUrl,
					query: {
						limit: _vm.limit,
						offset: _vm.offset(_vm.next - 1)
					}
				}}},[_vm._v("\n\t\t\t\tNext\n\t\t\t\t"),_c('span',{staticClass:"icon"},[_c('svg',{attrs:{"width":"20","height":"20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"none","d":"M20 0H0v20h20z"}}),_c('path',{attrs:{"d":"M3.333 9.167h10.141L8.816 4.508l1.183-1.175L16.666 10l-6.667 \n\t\t\t\t\t\t6.667-1.175-1.175 4.65-4.659H3.333V9.167z","fill":"#fff"}})])])])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }