<template>
	<div class="contacts--list__footer">
		<ul class="pagination">
			<li class="pagination__item prev button__disabled" v-if="prev === 0">
				<span class="icon">
					<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill="none" d="M0 0h20v20H0z" />
						<path
							d="M16.667 9.167H6.526l4.658-4.659-1.183-1.175L3.334 10l6.667 6.667 
						1.175-1.175-4.65-4.659h10.141V9.167z"
							fill="#C4C4C4"
						/>
					</svg>
				</span>
				Prev
			</li>
			<li class="pagination__item" v-else>
				<router-link
					:to="{
						name: baseUrl,
						query: {
							limit: limit,
							offset: offset(prev - 1)
						}
					}"
				>
					<span class="icon">
						<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill="none" d="M0 0h20v20H0z" />
							<path
								d="M16.667 9.167H6.526l4.658-4.659-1.183-1.175L3.334 10l6.667 6.667 
							1.175-1.175-4.65-4.659h10.141V9.167z"
								fill="#C4C4C4"
							/>
						</svg>
					</span>
					Prev
				</router-link>
			</li>
			<li v-for="page in pagesArray" :key="page" :class="{ active: current === page }">
				<router-link
					class="pagination__item"
					v-if="current !== page"
					:to="{
						name: baseUrl,
						query: {
							limit: limit,
							offset: offset(page - 1)
						}
					}"
				>
					{{ page }}
				</router-link>
				<span class="pagination__item current" v-else>{{ page }}</span>
			</li>
			<li class="pagination__item next button__disabled" v-if="current === endIndex">
				Next
				<span class="icon">
					<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill="none" d="M20 0H0v20h20z" />
						<path
							d="M3.333 9.167h10.141L8.816 4.508l1.183-1.175L16.666 10l-6.667 
						6.667-1.175-1.175 4.65-4.659H3.333V9.167z"
							fill="#fff"
						/>
					</svg>
				</span>
			</li>
			<li class="pagination__item next" v-else>
				<router-link
					:to="{
						name: baseUrl,
						query: {
							limit: limit,
							offset: offset(next - 1)
						}
					}"
				>
					Next
					<span class="icon">
						<svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill="none" d="M20 0H0v20h20z" />
							<path
								d="M3.333 9.167h10.141L8.816 4.508l1.183-1.175L16.666 10l-6.667 
							6.667-1.175-1.175 4.65-4.659H3.333V9.167z"
								fill="#fff"
							/>
						</svg>
					</span>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		options: {
			type: Object,
			default() {
				return {
					limit: 2,
					offset: 0,
					total: 0,
					baseUrl: '/'
				};
			}
		}
	},
	data() {
		return {
			total: this.options.total,
			limit: this.options.limit,
			baseUrl: this.options.baseUrl,
			maxPages: 10,
			totalPages: null
		};
	},
	created() {
		this.totalPages = Math.ceil(this.total / this.maxPages);
		if (this.baseUrl.includes('detail')) {
			this.baseUrl = 'class-detail';
		}
	},
	computed: {
		pages() {
			return Math.min(Math.ceil(this.total / this.limit), this.maxPages);
		},
		current() {
			return Math.ceil(this.options.offset / this.limit) + 1;
		},
		prev() {
			return this.current > 1 ? this.current - 1 : 0;
		},
		next() {
			return this.current < this.endIndex ? this.current + 1 : 0;
		},
		pagesArray() {
			const arr = [];
			for (let i = this.startIndex; i <= this.endIndex; i += 1) {
				arr[i] = i;
			}
			arr.splice(0, this.startIndex);
			return arr;
		},
		startIndex() {
			const midpoint = Math.ceil(this.maxPages / 2);
			if (this.current <= midpoint) {
				return 1;
			}
			return this.current === this.totalPages ? this.totalPages - this.maxPages + 1 : this.current - midpoint;
		},
		endIndex() {
			if (this.pages < Math.ceil(this.maxPages / 2)) {
				return this.pages;
			}
			return Math.min(this.startIndex + this.maxPages, this.totalPages);
		}
	},
	methods: {
		offset(page) {
			return page <= 0 ? 0 : this.limit * page;
		}
	}
};
</script>

<style lang="scss">
.current {
	background: #ffa137;
	color: #fff;
}
</style>
