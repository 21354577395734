<template>
	<div>
		<div class="contacts--list__item">
			<label class="check">
				<input type="checkbox" />
				<div class="check-mark"></div>
			</label>
			<div class="contacts__info">
				<div class="info__primary">
					<p class="info__name">{{ fullName }}</p>
					<p class="info__email">{{ audit.email }}</p>
				</div>
			</div>
			<p class="info__address">{{ audit.title }}</p>
			<p class="info__address">{{ audit.description }}</p>
			<p class="info__address">{{ audit.created_at | date }}</p>
			<p>
				<router-link
					:to="{ name: 'audit-detail', params: { id: audit.id } }"
					class="btn btn__primary"
					id="auditDetail"
				>
					View Detail
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'auditItem',
	props: {
		auditData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			audit: this.auditData,
			itemData: '',
			mapStatus: ''
		};
	},
	computed: {
		fullName() {
			return `${this.audit.user.firstname} ${this.audit.user.lastname}`;
		}
	},
	filters: {
		date(val) {
			return moment(val).format('llll');
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 1fr 1fr 2fr 1fr 0.4fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
